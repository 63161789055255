import React from 'react';
import Layout from '../layouts/Layout';
import I18n from '../utils/I18n';

const HomeTemplate = data => {
  const {pageContext: {items, lang}} = data;
  const i18n = new I18n({lang});

  return (
    <Layout>
      <div className="custom-container--large slds-container--center">
        <div className="slds-p-around--xx-large slds-text-align--center Fz(24)">
          {i18n.text('homepage-title')}
        </div>
        
        <div className="slds-grid slds-grid--align-center">
          {items.map((item, i) => {
            return (
              <div key={i+1} className="slds-size--1-of-3">
                <div className="card">
                  <div className="slds-text-align--center Fz(18) Fw(b)">
                    <a href={`/${lang}/starter-site/${item.slug}/`}>{item.name}</a>
                  </div>
                  <div className="slds-text-align--center">{item.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default HomeTemplate;
